input {
    &.form-control {
        background: #ffffff;
        border-radius: 5px;
        height: 46px;
        color: #586071;
        padding: 15px;
        font-size: 14px;
        border: 1px solid #eaeaea;
    }
}

label {
    font-size: 14px;
    color: #586071;
    line-height: 25px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Toast Customization */

.Toastify__toast {
  background: #084ded !important;
  backdrop-filter: blur(6px);
  font-weight: 600;
  padding: 1rem 10px !important;
  border-radius: 12px !important;
  box-shadow: 0px 0px 10px rgb(255 255 255 / 70%) !important;
}


.loading {
  position: fixed;
  z-index: 9999;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0000008c;
}

.withdrawMinMax{
  display: flex;
  align-items: center;
  justify-content:space-between;
}
select {
    background: #ffffff url('../../../assets/images/down1.png') no-repeat center right 7px;
    padding: 10px 10px;
    padding-right: 30px;
    border-radius: 5px;
    color: #000000;
    font-size: 13px !important;
    font-weight: 300;
    appearance: none;
    border: 1px solid #d9d9d9;
    width: 100%;

    height: 50px;
}

form {
    select {
        background: #ffffff url('../../../assets/images/down1.png') no-repeat center right 7px;
        padding: 10px 10px;
        padding-right: 30px;
        border-radius: 25px;
        font-size: 14px;
        color: #141731;
        appearance: none;
        width: 100%;
    }
}

.filterCnt {
    select {
        background: #f9f9f9 url('../../../assets/images/down1.png') no-repeat center right 7px;
        padding: 10px 10px;
        padding-right: 30px;
        border-radius: 5px;
        font-size: 14px;
        color: #141731;
        border: 1px solid #e6e6e6;
        appearance: none;
    }
}
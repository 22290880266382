/*--||
Common Use Start
||--*/


* {
    box-sizing: border-box;
}

@font-face {
    font-family: 'Segoe UI Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Regular'), url('../assets/fonts/Segoe UI.woff') format('woff');
}

@font-face {
    font-family: 'Segoe UI Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Bold'), url('../assets/fonts/Segoe UI Bold.woff') format('woff');
}

:root {
    --font-Inter: "Inter", sans-serif;
    --font-Nunito: "Nunito Sans", sans-serif;
    --font-SegoeRegular: 'Segoe UI Regular';
    --font-SegoeBold: 'Segoe UI Bold';
    --font-Poppins: "Poppins", sans-serif;
    --body-bg: #F2F2F2;
    --prmy-color: #353f52;
    --secondary-color: #586071;
    --text-white: #ffffff;
    --text-blue: #1652f0;
    --text-green: #57b793;
    --btn-bg: #1652f0;
    --btn-bghov: #1d4ecd;
    --btn-greenbg: #42c174;
    --btn-graybg: #f5f5f5;
    --btn-redbg: #f3263f;
    --bord-col: #d9d9d9;
}

//color-variables
$bdy-bg: var(--body-bg);
$Prmy-color: var(--prmy-color);
$secondary-color: var(--secondary-color);
$text-white: var(--text-white);
$text-blue: var(--text-blue);
$text-green: var(--text-green);
$btn-bg: var(--btn-bg);
$btn-bghov: var(--btn-bghov);
$btn-greenbg: var(--btn-greenbg);
$btn-graybg: var(--btn-graybg);
$btn-redbg: var(--btn-redbg);
$-bord-col: var(---bord-col);

img {
    max-width: 100%;
}

html {
    position: relative;
    overflow-x: hidden;
}

body {
    font-family: var(--font-Inter);
    background: $text-white;
    font-size: 14px;
    font-weight: 400;
    color: $Prmy-color;
}

a:hover {
    text-decoration: none;
}

.form-control:focus {

    box-shadow: none;
}

@media only screen and (min-width: 992px) {
    .container {
        max-width: 948px;
    }
}

.btn:focus {
    box-shadow: none;
}

*:focus-visible {
    outline: 0px;
}

a:hover {
    text-decoration: none;
}

@mixin FlxWrap {
    display: flex;
    flex-wrap: wrap;
}

@mixin Flx-al-cen {
    display: flex;
    align-items: center;
}

@mixin flx-al-btwn-wrp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

@mixin flx-al-btwn {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@mixin btn-flx-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin trans04s {
    transition: .4s all ease-in-out;
}

@mixin trans06s {
    transition: .6s all ease-in-out;
}

@mixin Txt13-400 {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.400000023841858;
}

@mixin Txt13-500 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.400000023841858;
}

@mixin Txt14-400 {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}

@mixin Txt14-500 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.600000023841858;
}

@mixin Txt15-400 {
    font-size: 15px;
    font-weight: 400;
    line-height: 29px;
}

@mixin Txt15-500 {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.600000023841858;
}

@mixin Txt16-400 {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
}

@mixin Txt16-500 {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
}

@mixin Txt17-500 {
    font-size: 17px;
    font-weight: 500;
    line-height: 1.7000000476837158;
}

@mixin Txt18-400 {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
}

@mixin Txt20-700 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
}

@mixin Txt24-400 {
    font-size: 26px;
    font-weight: 400;
    line-height: 0.800000011920929;
}

@mixin Txt24-500 {
    font-size: 26px;
    font-weight: 500;
    line-height: 0.800000011920929;
}

.CmnBtn {
    min-width: 107px;
    min-height: 46px;
    background: $btn-bg;
    @include Txt16-500();
    color: $text-white;
    border: 0;
    position: relative;
    border-radius: 6px;
    @include btn-flx-center();
    font-family: var(--font-SegoeBold);

    @media(max-width: 360px) {
        font-size: 17px;
    }

    @media (min-width:361px) and (max-width: 375px) {
        font-size: 18px;
    }

    @media (min-width:376px) and (max-width: 400px) {
        font-size: 19px;
    }

    @media (min-width:401px) and (max-width: 767px) {
        font-size: 20px;
    }


    &:hover {
        background: $btn-bghov;
        color: $text-white;
        @include trans04s();
    }
}

/*--||
Common Use End
||--*/


/*=========================================================|| Home page content Start ||======================================================*/

.CntLdDv {
    overflow-x: hidden;
    position: relative;

}

.hdNavMenu {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 342px !important;
    height: 100%;
    background-color: $text-white;
    box-shadow: 4px 0px 36px rgba(0, 0, 0, 0.16);
    transform: translateX(-100%);
    transition: 0.3s;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .SdNav {
        .SdNavTp {
            position: relative;
            padding: 2rem 24px 0rem 50px;

            background: $text-white;
            min-height: 100px;
            display: flex;
            justify-content: center;
            flex-direction: column;

            @media(max-width:575px) {
                padding: 2rem 20px 0px 20px;
            }

            .SdCls {
                position: absolute;
                right: 15px;
                top: 15px;
                cursor: pointer;
                filter: invert(1);
            }

            p {
                @include Txt18-400();
                color: $text-blue;
                margin-bottom: 0.25rem;
                font-weight: 600;
                font-family: var(--font-Nunito);

                @media(max-width: 360px) {
                    font-size: 15px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 16px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 17px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 18px;
                }


            }

            h4 {
                @include Txt14-500();
                color: $secondary-color;
                margin-bottom: 0;
                font-family: var(--font-SegoeRegular);

                @media(max-width: 360px) {
                    font-size: 13px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 14px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 15px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 16px;
                }


            }
        }

        .SdNavMn {
            position: relative;


            h5 {
                @include Txt18-400();
                padding: 1rem 24px 1rem 50px;
                font-family: var(--font-Nunito);
                font-weight: 600;

                @media(max-width: 360px) {
                    font-size: 20px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 21px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 22px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 23px;
                }


                @media(max-width: 575px) {
                    padding: 1rem 20px 1rem 20px;
                }

            }

            ul {
                padding-left: 0;

                li {
                    list-style: none;
                    padding: 1rem 24px 1rem 50px;
                    background: transparent;
                    cursor: pointer;
                    margin-bottom: 0.25rem;

                    @media(max-width: 575px) {
                        padding: 0.35rem 20px 0.35rem 20px;
                    }

                    a {
                        @include Txt16-400();
                        color: $secondary-color;
                        font-family: var(--font-SegoeRegular);

                        @media(max-width: 575px) {
                            font-size: 13px;
                        }
                    }

                    &:hover,
                    &.active {
                        background: $btn-graybg;
                        @include trans04s();
                    }
                }
            }
        }
    }
}


.sbMenu .hdNavMenu {
    transform: translateX(0%);
}

.FtrSec {
    p {
        padding: 1.5rem 0 1rem;
        text-align: center;
        margin-bottom: 0;
        font-size: 12px;
    }
}

.DfltCntLd {
    position: absolute;
    background: #2000F0;
    @include btn-flx-center();
    width: 56px;
    height: 56px;
    border-radius: 10px;
    z-index: 10;
    transition: background-color 0.3s, transform 0.2s;


}

.MainBg {
    position: relative;
    overflow: hidden;
    z-index: 1;

    @media(max-width:991px) {
        background: $text-white;

        .container {
            padding: 0;
            width: 100%;
            max-width: 100%;
        }
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background: url('../assets/images/main-bg.png') no-repeat;
        background-size: 100% 100%;
        height: 556px;
        width: 100%;

        @media(max-width: 991px) {
            display: none;
        }
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        background: url('../assets/images/main-bg-bot.png') no-repeat;
        background-size: 100% 100%;
        height: 646px;
        width: 100%;
        z-index: -1;

        @media(max-width: 991px) {
            display: none;
        }
    }
}

.HmPg {
    .DfltTp {
        padding: 24px 44px;
        border-radius: 25px;
        margin-top: 1rem;
        background: $btn-bg;

        @media(max-width: 991px) {
            padding: 16px;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            margin-top: 0;
        }

        .DfltTpIg {
            @include flx-al-btwn-wrp();

            img {
                cursor: pointer;
            }
        }

        p {
            @include Txt16-500();
            line-height: 35px;
            margin: 1.5rem 0 1rem;
            color: $btn-graybg;
            font-weight: 700;

            @media(max-width: 360px) {
                font-size: 34px;
            }

            @media (min-width:361px) and (max-width: 375px) {
                font-size: 36px;
            }

            @media (min-width:376px) and (max-width: 400px) {
                font-size: 38px;
            }

            @media (min-width:401px) and (max-width: 767px) {
                font-size: 40px;
            }


        }

        h1 {
            font-size: clamp(20px, 3.33vw, 40px);
            color: $text-white;
            font-weight: 600;
            line-height: 1.2000000476837158;
            margin-bottom: 2rem;

            @media(max-width: 360px) {
                font-size: 17px;
            }

            @media (min-width:361px) and (max-width: 375px) {
                font-size: 18px;
            }

            @media (min-width:376px) and (max-width: 767px) {
                font-size: 19px;
            }


        }

        h6 {
            @include Txt14-500();
            line-height: 1.399999976158142;
            color: $text-white;
        }

        &.ByCnTp {
            background: transparent;

            span {
                font-size: 22px;
                font-weight: 500;
                line-height: 1.2000000476837158;

            }
        }
    }

    .HmMrktTbl {
        border-radius: 16px;
        background: $text-white;
        padding: 1.5rem;
        margin-top: 1rem;

        @media(max-width: 991px) {
            margin-top: 0rem;
            box-shadow: none;
            padding: 0.75rem;
        }

        h2 {
            @include Txt18-400();
            font-weight: 700;
            line-height: 1.2000000476837158;
            margin-bottom: 16px;

            @media(max-width: 360px) {
                font-size: 19px;
            }

            @media (min-width:361px) and (max-width: 375px) {
                font-size: 20px;
            }

            @media (min-width:376px) and (max-width: 400px) {
                font-size: 21px;
            }

            @media (min-width:401px) and (max-width: 767px) {
                font-size: 22px;
            }


        }

        .HmMrktSrchDv {
            position: relative;

            .form-control {
                border-radius: 28px;
                box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05999999865889549);
                height: 46px;
                padding-left: 45px;
                font-size: 14px;
                font-weight: 300;
                line-height: 1.2000000476837158;
            }

            img {
                position: absolute;
                top: 13px;
                left: 15px;
            }
        }

        .HmMrktTbs {
            .nav-tabs {
                border-bottom: 0;
                margin-bottom: 2rem;

                @media(max-width:991px) {
                    margin-bottom: 12px;
                }

                .nav-item {
                    margin-right: 10px;

                    .nav-link {
                        border-radius: 100px;
                        background: #f4f4f4;
                        height: 46px;
                        min-width: 90px;
                        padding: 0 1rem;
                        border: 1px solid #d9d9d9;
                        @include Txt16-400();
                        color: $secondary-color;
                        line-height: 1.2000000476837158;
                        @include btn-flx-center();
                        cursor: pointer;
                        font-family: var(--font-SegoeRegular);

                        @media(max-width: 360px) {
                            font-size: 14px;
                            height: 30px;
                        }

                        @media (min-width:361px) and (max-width: 375px) {
                            font-size: 15px;
                            height: 31px;
                        }

                        @media (min-width:376px) and (max-width: 400px) {
                            font-size: 16px;
                            height: 33px;
                        }

                        @media (min-width:401px) and (max-width: 767px) {
                            font-size: 17px;
                            height: 35px;
                        }


                        &:hover,
                        &.active {
                            background: $btn-bg;
                            border-color: $btn-bg;
                            color: $text-white;
                            @include trans04s();
                        }
                    }
                }
            }

            .table {
                thead th {
                    border: 0;
                }

                tbody {
                    tr {
                        td {
                            border: 0;
                            vertical-align: middle;
                            white-space: nowrap;
                            padding-left: 0;


                            .CnDts {
                                @include Flx-al-cen();
                            }

                            h5 {
                                @include Txt13-500();
                                font-family: var(--font-Nunito);
                                font-weight: 700;
                                margin-bottom: 0;

                                @media(max-width: 360px) {
                                    font-size: 13px;
                                }

                                @media (min-width:361px) and (max-width: 375px) {
                                    font-size: 14px;
                                }

                                @media (min-width:376px) and (max-width: 767px) {
                                    font-size: 15px;
                                }


                                &.TxtGrn {
                                    color: $text-green;
                                }

                                &.Txtdgr {
                                    color: red;
                                }

                                &.MrktTbsTd3H5 {
                                    font-family: var(--font-SegoeRegular);
                                    font-weight: 400;

                                    @media(max-width: 360px) {
                                        font-size: 15px;
                                    }

                                    @media (min-width:361px) and (max-width: 375px) {
                                        font-size: 15px;
                                    }

                                    @media (min-width:376px) and (max-width: 767px) {
                                        font-size: 16px;
                                    }


                                }
                            }

                            h6 {
                                @include Txt13-400();

                                color: $secondary-color;
                                margin-bottom: 0 !important;
                                font-family: var(--font-SegoeRegular);

                                @media(max-width: 360px) {
                                    font-size: 13px;
                                }

                                @media (min-width:361px) and (max-width: 375px) {
                                    font-size: 14px;
                                }

                                @media (min-width:376px) and (max-width: 400px) {
                                    font-size: 15px;
                                }

                                @media (min-width:401px) and (max-width: 767px) {
                                    font-size: 15px;
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}

.MainInrBg {
    position: relative;
    overflow: hidden;
    z-index: 1;
    min-height: calc(100vh - 58px);

    @media(max-width:991px) {
        background: $text-white;

        .container {
            padding: 0;
            width: 100%;
            max-width: 100%;
        }
    }


}

.CmnMdl {
    .modal-dialog {
        justify-content: center;
        align-items: flex-end;
        min-height: calc(100% - 0rem);
    }

    .modal-content {
        background: $text-white;
        border-radius: 16px;
        padding: 1.5rem;

        @media(max-width:767px) {
            padding: 1rem;
        }

        .modal-body {
            padding: 0;
        }

        .CmnMdlHdr {
            @include flx-al-btwn-wrp();
            margin-bottom: 2rem;

            @media(max-width:767px) {
                margin-bottom: 0.5rem;
                padding-bottom: 0.5rem !important;
            }

            h5 {
                @include Txt16-500();
                line-height: 30px;
                margin-bottom: 0;
                font-family: var(--font-SegoeBold);

                @media(max-width: 360px) {
                    font-size: 17px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 18px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 19px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 20px;
                }


            }
        }
    }

    &.StgsMdl {
        .modal-dialog {
            align-items: center;
            min-height: calc(100% - 1rem);
        }

        .modal-content {
            max-width: 338px;
        }

        .StgsMdlDts {
            .StgsMdlDtsCt {
                @include flx-al-btwn-wrp();
                margin-bottom: 1rem;

                .StgsMdlDtsCtL {
                    @include Flx-al-cen();
                    cursor: pointer;

                    span {
                        @include Txt14-400();
                        line-height: 22px;
                        font-family: var(--font-SegoeRegular);

                        @media(max-width: 360px) {
                            font-size: 13px;
                        }

                        @media (min-width:361px) and (max-width: 375px) {
                            font-size: 14px;
                        }

                        @media (min-width:376px) and (max-width: 400px) {
                            font-size: 15px;
                        }

                        @media (min-width:401px) and (max-width: 767px) {
                            font-size: 16px;
                        }


                    }
                }
            }
        }
    }

    &.GnrtPinMdl {
        .modal-dialog {
            align-items: center;
            min-height: calc(100% - 1rem);
        }

        .modal-content {
            max-width: 340px;

            .GnrtPinDts {
                label {
                    @include Txt16-400();
                    line-height: 1.2000000476837158;
                    font-family: var(--font-Nunito);
                    font-weight: 600;

                    @media(max-width: 360px) {
                        font-size: 15px;
                    }

                    @media (min-width:361px) and (max-width: 375px) {
                        font-size: 16px;
                    }

                    @media (min-width:376px) and (max-width: 400px) {
                        font-size: 17px;
                    }

                    @media (min-width:401px) and (max-width: 767px) {
                        font-size: 18px;
                    }


                }

                .form-control {
                    width: 54px;
                    height: 54px;
                    border-radius: 8px;
                    margin-right: 1rem;
                    border-color: #d9d9d9;
                }
            }
        }
    }

    &.ChsLangMdl {
        .modal-dialog {
            align-items: center;
            min-height: calc(100% - 1rem);
        }

        .modal-content {
            max-width: 400px;

            .ChsLangDts {
                .ChsLangLst {
                    @include flx-al-btwn-wrp();
                    padding: 1rem 0;
                    border-bottom: 1px solid #d9d9d9;
                    cursor: pointer;

                    a {
                        @include Txt16-400();
                        color: $Prmy-color;
                        font-family: var(--font-SegoeRegular);

                        @media(max-width: 360px) {
                            font-size: 13px;
                        }

                        @media (min-width:361px) and (max-width: 375px) {
                            font-size: 14px;
                        }

                        @media (min-width:376px) and (max-width: 400px) {
                            font-size: 15px;
                        }

                        @media (min-width:401px) and (max-width: 767px) {
                            font-size: 16px;
                        }


                    }

                    img {
                        display: none;
                    }

                    &:hover {
                        @include trans04s();

                        img {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    &.ByCnMdl {
        .ByCnLstCnt {
            @include flx-al-btwn-wrp();
            margin-bottom: 1.5rem;
            cursor: pointer;

            .ByCnLstCntL {
                @include Flx-al-cen();
            }

            .ByCnLstCntR {
                text-align: right;
            }

            h5 {
                font-size: 13px;
                font-weight: 700;
                line-height: 1.2000000476837158;
                margin-bottom: 0;
                font-family: var(--font-Nunito);

                @media(max-width: 360px) {
                    font-size: 13px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 14px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 15px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 15px;
                }

                &.MrktTbsTd3H5 {
                    font-weight: 400;
                    margin-bottom: 0;

                    @media(max-width: 360px) {
                        font-size: 13px;
                    }

                    @media (min-width:361px) and (max-width: 375px) {
                        font-size: 14px;
                    }

                    @media (min-width:376px) and (max-width: 400px) {
                        font-size: 15px;
                    }

                    @media (min-width:401px) and (max-width: 767px) {
                        font-size: 15px;
                    }


                }
            }

            h6 {
                font-size: 13px;
                font-weight: 300;
                line-height: 1.2000000476837158;
                font-family: var(--font-SegoeRegular);

                @media(max-width: 360px) {
                    font-size: 13px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 14px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 15px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 15px;
                }


                span {

                    &.TxtGrn {
                        color: $text-green;
                    }

                    &.Txtdgr {
                        color: red;
                    }
                }
            }
        }
    }

    &.ByCnDelMdl {
        .ByCnDelTp {
            @include flx-al-btwn-wrp();
            margin-bottom: 2rem;

            @media(max-width: 767px) {
                margin-bottom: 0.5rem;
            }

            .ByCnDelTpL {
                @include Flx-al-cen();
            }

            h5 {
                @include Txt14-500();
                line-height: 1.2000000476837158;
                font-family: var(--font-SegoeRegular);
                font-weight: 400;
                margin-bottom: 0.25rem;

                @media(max-width: 360px) {
                    font-size: 14px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 14px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 15px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 16px;
                }


            }

            h6 {
                font-size: 14px;

                line-height: 1.2000000476837158;
                font-family: var(--font-Nunito);
                font-weight: 600;
                margin-bottom: 0.5rem;

                @media(max-width: 360px) {
                    font-size: 13px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 14px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 15px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 16px;
                }


            }

            span {
                @include Txt14-400();
                line-height: 1.2000000476837158;

                @media(max-width: 767px) {
                    font-size: 15px;
                }

            }
        }

        .ByCnDelTm {
            margin-bottom: 2rem;

            @media(max-width: 767px) {
                margin-bottom: 0.5rem;
            }

            label {
                font-family: var(--font-Nunito);
                font-weight: 600;
                margin-bottom: 0.25rem;

                @media(max-width: 767px) {
                    font-size: 15px;
                }

                @media (min-width:361px) and (max-width: 767px) {
                    font-size: 16px;
                }


            }

            .ByCnDelTmSplt {
                @include flx-al-btwn-wrp();

                .ByCnDelTmSpltMn {
                    background: transparent;
                    border-radius: 6px;
                    border: 1px solid #d9d9d9;
                    min-width: 100px;
                    min-height: 40px;
                    padding: 0 0.5rem;

                    span {
                        @include Txt14-400();
                        color: $Prmy-color;
                        font-family: var(--font-SegoeRegular);

                        @media(max-width: 360px) {
                            font-size: 13px;
                        }

                        @media (min-width:361px) and (max-width: 375px) {
                            font-size: 14px;
                        }

                        @media (min-width:376px) and (max-width: 400px) {
                            font-size: 15px;
                        }

                        @media (min-width:401px) and (max-width: 767px) {
                            font-size: 16px;
                        }


                    }
                }

                .ByCnDelTmSpltR {
                    @include Flx-al-cen();

                    .ByCnDelTmSpltGrn {
                        clip-path: polygon(0px 6px, 0px 6px, 0.07852983px 5.02676847px, 0.30588384px 4.10353536px, 0.66970881px 3.24265389px, 1.15765152px 2.45647728px, 1.75735875px 1.75735875px, 2.45647728px 1.15765152px, 3.24265389px 0.66970881px, 4.10353536px 0.30588384px, 5.02676847px 0.07852983px, 6px 9.9333241925913E-32px, calc(100% - 0px) 0px, calc(100% - 14.9688px) calc(100% - 3.712px), calc(100% - 14.9688px) calc(100% - 3.712px), calc(100% - 15.2836613px) calc(100% - 3.0622131px), calc(100% - 15.6681904px) calc(100% - 2.4633728px), calc(100% - 16.1162451px) calc(100% - 1.9195897px), calc(100% - 16.6216832px) calc(100% - 1.4349744px), calc(100% - 17.1783625px) calc(100% - 1.0136375px), calc(100% - 17.7801408px) calc(100% - 0.6596896px), calc(100% - 18.4208759px) calc(100% - 0.3772413px), calc(100% - 19.0944256px) calc(100% - 0.1704032px), calc(100% - 19.7946477px) calc(100% - 0.043285899999994px), calc(100% - 20.5154px) calc(100% - 7.105427357601E-15px), 6px calc(100% - 0px), 6px calc(100% - 0px), 5.02676847px calc(100% - 0.078530100000002px), 4.10353536px calc(100% - 0.30588479999999px), 3.24265389px calc(100% - 0.6697107px), 2.45647728px calc(100% - 1.1576544px), 1.75735875px calc(100% - 1.7573625px), 1.15765152px calc(100% - 2.4564816px), 0.66970881px calc(100% - 3.2426583px), 0.30588384px calc(100% - 4.1035392px), 0.07852983px calc(100% - 5.0267709px), 9.9333241925913E-32px calc(100% - 6px), 0px 6px);
                        background: #42c174;
                        width: 85px;
                        height: 40px;
                        @include btn-flx-center();
                        @include Txt14-500();
                        color: $text-white;
                        font-family: var(--font-Nunito);
                        font-family: 600;

                        @media(max-width: 360px) {
                            font-size: 15px;
                        }

                        @media (min-width:361px) and (max-width: 375px) {
                            font-size: 16px;
                        }

                        @media (min-width:376px) and (max-width: 400px) {
                            font-size: 17px;
                        }

                        @media (min-width:401px) and (max-width: 767px) {
                            font-size: 18px;
                        }

                        &.gray {
                            background: $btn-graybg;
                            color: #353f52;
                        }
                    }

                    .ByCnDelTmSpltGray {
                        clip-path: polygon(calc(100% - 0px) calc(100% - 6px), calc(100% - 0px) calc(100% - 6px), calc(100% - 0.078530099999981px) calc(100% - 5.0267709px), calc(100% - 0.30588479999997px) calc(100% - 4.1035392px), calc(100% - 0.66971070000001px) calc(100% - 3.2426583px), calc(100% - 1.1576544px) calc(100% - 2.4564816px), calc(100% - 1.7573625px) calc(100% - 1.7573625px), calc(100% - 2.4564816px) calc(100% - 1.1576544px), calc(100% - 3.2426583px) calc(100% - 0.6697107px), calc(100% - 4.1035392px) calc(100% - 0.3058848px), calc(100% - 5.0267709px) calc(100% - 0.078530099999995px), calc(100% - 6px) calc(100% - 7.105427357601E-15px), 0px calc(100% - 0px), 14.9688px 3.71201px, 14.9688px 3.71201px, 15.2836613px 3.06221067px, 15.6681904px 2.46336256px, 16.1162451px 1.91957549px, 16.6216832px 1.43495928px, 17.1783625px 1.01362375px, 17.7801408px 0.65967872px, 18.4208759px 0.37723401px, 19.0944256px 0.17039944px, 19.7946477px 0.04328483px, 20.5154px 5.4196962859979E-32px, calc(100% - 6px) 0px, calc(100% - 6px) 0px, calc(100% - 5.0267709px) 0.07852983px, calc(100% - 4.1035392px) 0.30588384px, calc(100% - 3.2426583px) 0.66970881px, calc(100% - 2.4564816px) 1.15765152px, calc(100% - 1.7573625px) 1.75735875px, calc(100% - 1.1576544px) 2.45647728px, calc(100% - 0.6697107px) 3.24265389px, calc(100% - 0.3058848px) 4.10353536px, calc(100% - 0.078530099999995px) 5.02676847px, calc(100% - 1.4210854715202E-14px) 6px, calc(100% - 0px) calc(100% - 6px));
                        background: $btn-graybg;
                        width: 85px;
                        height: 40px;
                        @include btn-flx-center();
                        @include Txt14-500();
                        margin-left: -12px;

                        @media(max-width: 360px) {
                            font-size: 15px;
                        }

                        @media (min-width:361px) and (max-width: 375px) {
                            font-size: 16px;
                        }

                        @media (min-width:376px) and (max-width: 400px) {
                            font-size: 17px;
                        }

                        @media (min-width:401px) and (max-width: 767px) {
                            font-size: 18px;
                        }

                        &.red {
                            background: #f3263f;
                            color: $text-white;
                        }
                    }
                }
            }

        }

        .ByCnDelPrzRng {
            margin-bottom: 1.5rem;

            @media(max-width:767px) {
                margin-bottom: 1rem;
            }

            h6 {
                font-size: 13px;
                font-weight: 300;
                line-height: 22px;
                font-family: var(--font-SegoeRegular);

                @media(max-width: 360px) {
                    font-size: 13px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 14px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 15px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 16px;
                }


            }

            label {
                font-family: var(--font-Nunito);
                font-weight: 600;
                margin-bottom: 0.25rem;

                @media(max-width: 360px) {
                    font-size: 15px;
                }

                @media (min-width:361px) and (max-width: 767px) {
                    font-size: 16px;
                }

            }
        }

        .EmailVrfyDtsInr {
            .form-control {
                border-radius: 6px;
                border: 1px solid #d9d9d9;

            }

            .input-group {


                input {
                    border-right: 0;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            .input-group-text {
                border: 1px solid #d9d9d9;
                border-left: 0;
            }

            ::placeholder {
                color: $secondary-color;
            }

            .EmailVrfyDYKDv {
                box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05999999865889549);
                border: 1px solid #e9e9e9;
                padding: 1rem;
                border-radius: 16px;
                margin: 2rem 0;

                h6 {
                    @include Flx-al-cen();
                    @include Txt18-400();
                    font-weight: 500;
                    line-height: 1.2000000476837158;
                }

                p {
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 1.600000023841858;
                    margin-left: 32px;
                    margin-bottom: 0;
                }
            }

            &.IPGrp {
                @include Flx-al-cen();
                margin-bottom: 1.5rem;

                @media(max-width:767px) {
                    margin-bottom: 0.5rem;
                }

                .form-group {
                    width: 100%;
                }

                .PurPrz {
                    min-width: 92px;
                    margin-right: 0.5rem;
                    border: 1px solid #d9d9d9;
                    border-radius: 6px;
                    height: 46px;
                    padding: 0 0.5rem;
                    @include btn-flx-center();
                    margin-top: -13px;
                    font-family: var(--font-Nunito);
                    font-weight: 700;
                }

                @media(max-width: 360px) {
                    font-size: 13px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 14px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 15px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 16px;
                }


            }
        }

        .ByCnDelAvlBal {
            @include flx-al-btwn-wrp();
            margin-bottom: 2rem;

            @media(max-width:767px) {
                margin-bottom: 1rem;
            }

            h6 {
                font-size: 13px;

                line-height: 1.7000000476837158;
                font-family: var(--font-Nunito);
                font-weight: 400;

                @media(max-width: 360px) {
                    font-size: 13px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 14px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 15px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 16px;
                }


            }

            a {
                font-size: 13px;

                line-height: 1.7000000476837158;
                color: $text-blue;
                font-family: var(--font-Nunito);
                font-weight: 400;

                @media(max-width: 360px) {
                    font-size: 13px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 14px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 15px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 16px;
                }


            }
        }

        .Entrust-btn {
            background: #42c174;

            &:hover {
                background: $text-green;
                @include trans04s();
            }

            &.redbg {
                background: #F3273F;

                &:hover {
                    background: #ab2837;
                    @include trans04s();
                }
            }
        }
    }

    &.TransSecMdl {
        .modal-content {
            padding: 0;
            max-width: 300px;

            .TransSecDts {
                margin-bottom: 3rem;

                .TransSecCnt {
                    padding: 1rem 0;
                    text-align: center;
                    border-bottom: 1px solid #D7D7E3;
                    font-family: var(--font-SegoeRegular);
                }
            }
        }
    }

    &.MineConfirmMdl {
        .modal-content {
            max-width: 350px
        }

        .MineConfirmBdy {
            h5 {
                @include Txt14-500();
                margin: 2rem 0 1rem;
                text-align: center;

                span {
                    color: $text-green;
                }
            }

            h6 {
                @include Txt14-400();
                line-height: 22px;
                text-align: center;
                margin-bottom: 36px;
            }

            p {
                font-size: 12px;
                font-weight: 300;
                line-height: 1.7000000476837158;
                margin-top: 1rem;
                text-align: center;
            }
        }
    }

    &.LnRqstMdl {
        .modal-dialog {
            align-items: center;
            min-height: calc(100% - 1rem);
        }

        .modal-content {
            max-width: 430px;

            .LnRqstBdy {
                a {
                    @include Txt16-400();

                    @media(max-width:767px) {
                        font-size: 14px;
                    }

                    span {
                        font-weight: 500;
                    }
                }

                .LnRqstInr {
                    @include flx-al-btwn-wrp();
                    margin-bottom: 1rem;

                    @media(max-width:767px) {
                        margin-bottom: 0.5rem;
                    }

                    h5 {
                        @include Txt14-400();
                        color: $secondary-color;
                    }

                    h6 {
                        @include Txt14-400();
                        color: $secondary-color;

                        span {
                            font-weight: 500;
                            color: $Prmy-color;
                        }
                    }
                }

                .AccRejBtn {
                    @include flx-al-btwn-wrp();
                    justify-content: space-around;

                    @media(max-width:767px) {
                        margin-top: 1rem !important;
                    }

                    .rejbtn {
                        background: $btn-redbg;
                        min-width: 172px;
                        margin-bottom: 0.5rem;

                        @media(max-width: 767px) {
                            min-width: 150px;
                        }
                    }

                    .actbtn {
                        background: $btn-greenbg;
                        min-width: 172px;
                        margin-bottom: 0.5rem;

                        @media(max-width: 767px) {
                            min-width: 150px;
                        }
                    }
                }
            }
        }
    }

    &.ChatMdl {
        .modal-content {
            max-width: 350px;

            .chatBdy {
                @include btn-flx-center();
                flex-direction: column;
            }
        }
    }

    &.RejMdl {
        .RejBdy {
            text-align: center;

            img {
                width: 150px;
            }

            h4 {
                @include Flx-al-cen();
                @include Txt16-400();
                color: $text-blue;
                display: block;

                @media(max-width:767px) {
                    font-size: 14px;
                }

                span {
                    font-weight: 500;
                }
            }

            h5 {
                @include Txt18-400();
                color: $secondary-color;

                @media(max-width:767px) {
                    font-size: 14px;
                }

                span {
                    font-weight: 500;
                }
            }
        }
    }
}

.EmailVrfyPg {
    .EmailVrfyTp {

        border-radius: 16px;

        @include flx-al-btwn-wrp();
        padding: 16px;
        margin-bottom: 1rem;

        @media(max-width:767px) {
            margin-bottom: 0.5rem;
            border-radius: 0;
        }

        font-family: var(--font-Nunito);
        font-weight: 600;

        @media(max-width: 360px) {
            font-size: 17px;
        }

        @media (min-width:361px) and (max-width: 375px) {
            font-size: 18px;
        }

        @media (min-width:376px) and (max-width: 767px) {
            font-size: 19px;
        }


        h4 {
            font-size: 22px;
            line-height: 1.2000000476837158;
            margin-bottom: 0;
            font-family: var(--font-Nunito);
            font-weight: 600;

            @media(max-width: 360px) {
                font-size: 17px;
            }

            @media (min-width:361px) and (max-width: 375px) {
                font-size: 18px;
            }

            @media (min-width:376px) and (max-width: 767px) {
                font-size: 19px;
            }


        }
    }

    .EmailVrfyDts {
        background: $text-white;
        border-radius: 16px;

        padding: 60px 2rem 120px;

        @media(max-width:767px) {
            padding: 20px;
        }

        .EmailVrfyDtsInr {
            background: $text-white;
            border-radius: 16px;
            box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05999999865889549);
            padding: 2rem;

            .form-control {
                border-radius: 6px;
                background: #f4f4f4;
                border: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                color: $secondary-color;
                font-family: var(--font-Nunito);
            }

            label {
                font-size: 14px;
                font-family: var(--font-Nunito);
                font-weight: 400;

                @media(max-width:767px) {
                    font-size: 13px;
                }
            }

            .input-group-text {
                border: 0;
                background: #f4f4f4;

                span {
                    color: $text-blue;
                }
            }

            ::placeholder {
                color: $secondary-color;
            }

            .EmailVrfyDYKDv {
                box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05999999865889549);
                border: 1px solid #e9e9e9;
                padding: 1rem;
                border-radius: 16px;
                margin: 2rem 0;

                h6 {
                    @include Flx-al-cen();
                    @include Txt18-400();
                    font-weight: 500;
                    line-height: 1.2000000476837158;
                }

                p {
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 1.600000023841858;
                    margin-left: 32px;
                    margin-bottom: 0;
                }
            }
        }
    }

}

.WltTp {
    position: relative;


    &.DfltTp {
        padding: 24px 16px 10px;

        p,
        h6 {
            color: $secondary-color;
            font-family: var(--font-Nunito);
            font-weight: 600;

            @media(max-width: 360px) {
                font-size: 13px;
            }

            @media (min-width:361px) and (max-width: 375px) {
                font-size: 14px;
            }

            @media (min-width:376px) and (max-width: 400px) {
                font-size: 15px;
            }

            @media (min-width:401px) and (max-width: 767px) {
                font-size: 16px;
            }

        }

        h1 {
            color: $Prmy-color;
            font-family: var(--font-Nunito);
            font-weight: 600;

            @media(max-width: 360px) {
                font-size: 19px;
            }

            @media (min-width:361px) and (max-width: 375px) {
                font-size: 20px;
            }

            @media (min-width:376px) and (max-width: 767px) {
                font-size: 21px;
            }


        }
    }
}

.ByCnDts {
    border-radius: 16px;
    background: $text-white;
    padding: clamp(16px, 3.67vw, 44px);

    @media(max-width:991px) {
        padding: 16px;
    }

    .ByCnDtsTp {
        border-radius: 16px;
        background: $btn-graybg;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05999999865889549);
        padding: 1.5rem;
        @include flx-al-btwn-wrp();
        margin-bottom: 2rem;

        @media(max-width:575px) {
            margin-bottom: 1rem;
            padding: 1rem;
        }

        .ByCnDtsTpL {
            @include Flx-al-cen();

            h5 {
                @include Txt14-500();
                line-height: 1.2000000476837158;
                font-family: var(--font-Nunito);
                font-weight: 700;
                margin-bottom: 0.25rem;

                @media(max-width: 360px) {
                    font-size: 16px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 16px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 17px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 18px;
                }


            }

            h6 {
                font-size: 14px;
                line-height: 1.2000000476837158;
                margin-bottom: 0;
                font-family: var(--font-Nunito);
                font-weight: 600;
            }
        }
    }

    .ByCnDtsVol {
        h2 {
            @include Txt24-500();
            line-height: 1.2000000476837158;
            font-weight: 600;

            @media(max-width: 360px) {
                font-size: 21px;
            }

            @media (min-width:361px) and (max-width: 375px) {
                font-size: 22px;
            }

            @media (min-width:376px) and (max-width: 400px) {
                font-size: 23px;
            }

            @media (min-width:401px) and (max-width: 767px) {
                font-size: 25px;
            }


        }

        h3 {
            @include Txt16-400();
            line-height: 1.2000000476837158;
            color: $text-green;
            margin-bottom: 1.5rem;

            @media(max-width: 360px) {
                font-size: 15px;
            }

            @media (min-width:361px) and (max-width: 375px) {
                font-size: 15px;
            }

            @media (min-width:376px) and (max-width: 400px) {
                font-size: 16px;
            }

            @media (min-width:401px) and (max-width: 767px) {
                font-size: 17px;
            }

            @media (min-width:426px) and (max-width: 767px) {
                font-size: 19px;
            }
        }

        h4 {
            font-size: 18px;
            line-height: 1.2000000476837158;
            margin: 2rem 0 1.5rem;
            font-family: var(--font-Nunito);
            font-weight: 700;

            @media(max-width: 360px) {
                font-size: 19px;
            }

            @media (min-width:361px) and (max-width: 375px) {
                font-size: 20px;
            }

            @media (min-width:376px) and (max-width: 400px) {
                font-size: 21px;
            }

            @media (min-width:401px) and (max-width: 425px) {
                font-size: 22px;
            }

            @media (min-width:426px) and (max-width: 767px) {
                font-size: 25px;
            }

            @media(max-width:575px) {
                margin: 1.5rem 0 1rem;
            }
        }

        .ByCnFun {
            padding-bottom: 1.5rem;

            .ByCnFunDts {
                margin-bottom: 1rem;
                @include flx-al-btwn-wrp();

                span {
                    @include Txt14-500();
                    margin: 0.5rem 0;
                    line-height: 1.2000000476837158;
                    font-family: var(--font-Nunito);
                    font-weight: 600;
                    @include flx-al-btwn();

                    @media(max-width: 360px) {
                        font-size: 15px;
                    }

                    @media (min-width:361px) and (max-width: 375px) {
                        font-size: 15px;
                    }

                    @media (min-width:376px) and (max-width: 4767px) {
                        font-size: 16px;
                    }


                }
            }
        }

        .EntrsBtnDv {
            padding: 0 16px;
            @include btn-flx-center();

            .EntrstBtn {
                width: 250px;
            }

            @media(max-width: 767px) {
                position: fixed;
                left: 0;
                right: 0;
                bottom: 5px;
                z-index: 5;

                .EntrstBtn {
                    width: 95%;
                }
            }
        }

    }
}

.HmPg.DeptPg {
    .DeptPgTp {
        h4 {
            @include Txt24-500();
            color: $text-white;
            margin-bottom: 0;
            font-family: var(--font-Nunito);
            font-weight: 600;

            @media(max-width: 360px) {
                font-size: 17px;
            }

            @media (min-width:361px) and (max-width: 375px) {
                font-size: 18px;
            }

            @media (min-width:376px) and (max-width: 400px) {
                font-size: 19px;
            }

            @media (min-width:401px) and (max-width: 767px) {
                font-size: 20px;
            }


        }

        .DfltTpIg {}
    }

    h3 {
        font-size: 32px;
        font-weight: 600;
        line-height: 1.2000000476837158;
        color: $text-white;
        text-align: center;

        @media(max-width: 767px) {
            font-size: 16px;
        }
    }

    p {
        @include Txt14-500();
        line-height: 20px;
        margin-bottom: 0.25rem;
        margin-top: 0;
        text-align: center;

        @media(max-width: 767px) {
            font-size: 13px;
        }
    }

    .DeptTbs {
        margin-top: 2rem;

        @media(max-width:767px) {
            margin-top: 1rem;
        }

        .nav-tabs {
            display: inline-flex;
            justify-content: center;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            padding: 0 0.25rem;
            height: 48px;
            align-items: center;
            border-radius: 6px;
            background: #f5f6f8;
            border-bottom: 0;
            width: 320px;


            .nav-item {
                .nav-link {
                    height: 38px;
                    border-radius: 6px;
                    @include Txt14-400();
                    color: $Prmy-color;
                    margin-bottom: 0;
                    @include btn-flx-center();
                    cursor: pointer;
                    min-width: 97px;
                    font-family: var(--font-SegoeBold);

                    @media(max-width: 360px) {
                        font-size: 12px;
                    }

                    @media (min-width:361px) and (max-width: 375px) {
                        font-size: 13px;
                    }

                    @media (min-width:376px) and (max-width: 400px) {
                        font-size: 14px;
                    }

                    @media (min-width:401px) and (max-width: 767px) {
                        font-size: 15px;
                    }


                    &:hover,
                    &.active {
                        background: $btn-bg;
                        color: $text-white;
                        @include trans04s();
                    }
                }
            }
        }
    }

    .DeptDfltBg {
        border-radius: 16px;
        background: $text-white;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05999999865889549);
        padding: 1.5rem;
        margin-top: 1rem;
        min-height: 658px;

        @media(max-width: 767px) {
            padding: 1rem;
            margin-top: 0;
            min-height: auto;
        }

        h3 {
            margin: 2rem 0 1.5rem;
            font-size: 22px;
            font-weight: 500;
            line-height: 1.2000000476837158;
            color: $Prmy-color;
            font-family: var(--font-SegoeRegular);

            @media(max-width: 767px) {
                margin: 0 0 1rem;
                font-size: 16px;
            }
        }

        .DeptFdsDvCnt {
            border-radius: 16px;
            background: $text-white;
            box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05999999865889549);
            padding: 1.5rem;
            margin-bottom: 2rem;

            @media(max-width: 767px) {
                padding: 1rem;
                margin-bottom: 1rem;

                svg {
                    width: 150px;
                    height: 150px;
                }

            }

            span {
                width: 82px;
                height: 31px;
                background: $btn-graybg;
                border-radius: 100px;
                @include btn-flx-center();
                margin-bottom: 1rem;
                font-weight: 300;
                color: $secondary-color;
                font-family: var(--font-SegoeRegular);

                @media(max-width: 360px) {
                    font-size: 12px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 13px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 14px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 15px;
                }


            }

            .DeptCpyDv {
                margin: 1.5rem 0;
                text-align: center;

                .form-control {
                    height: 46px;
                    background: $btn-graybg;
                    border-radius: 100px;
                    font-weight: 300;
                    color: $secondary-color;
                    border: 0;
                    margin: 0.25rem 0;
                }

                .CpyBtn {
                    border-radius: 100px;
                    font-weight: 400;
                    margin: 0.25rem 0;
                }

                a {
                    text-align: center;
                    color: $text-blue;
                    font-weight: 600;
                }


                .copyCls {
                    text-align: center;
                    color: $text-blue;
                    font-weight: 600;
                    cursor: pointer;
                    word-break: break-all;
                    font-size: 12px;

                    img.CPyIcn {
                        filter: invert(70%) sepia(84%) saturate(6556%) hue-rotate(226deg) brightness(97%) contrast(94%);
                        width: 18px;
                    }
                }

            }

            .DeptFdsDvCntFrmGrp {
                label {
                    font-size: 14px;
                    font-family: var(--font-Nunito);
                    font-weight: 400;

                    @media(max-width:767px) {
                        font-size: 13px;
                    }
                }

                .form-control {
                    border-radius: 6px;
                    background: #f4f4f4;
                    border: 0;
                    font-size: 14px;
                    line-height: 1.399999976158142;
                    color: $secondary-color;
                    font-family: var(--font-Nunito);
                    font-weight: 600;

                    @media(max-width: 360px) {
                        font-size: 13px;
                    }

                    @media (min-width:361px) and (max-width: 375px) {
                        font-size: 14px;
                    }

                    @media (min-width:376px) and (max-width: 400px) {
                        font-size: 15px;
                    }

                    @media (min-width:401px) and (max-width: 767px) {
                        font-size: 16px;
                    }


                }

                .IptGrpDv {
                    position: relative;

                    .IPtGrpIg {
                        position: absolute;
                        left: 10px;
                        top: 12px;
                    }

                    .form-control {
                        padding-left: 40px;
                    }

                    .IptGrpCnLmt {
                        @include Flx-al-cen();
                        position: absolute;
                        right: 15px;
                        top: 13px;

                        h6 {
                            @include Txt14-400();
                            color: #99A1B0;
                            margin-bottom: 0;
                            margin-right: 5px;
                        }

                        a {
                            color: $secondary-color;
                            font-family: var(--font-Nunito);
                            font-weight: 600;

                            @media(max-width: 360px) {
                                font-size: 13px;
                            }

                            @media (min-width:361px) and (max-width: 375px) {
                                font-size: 14px;
                            }

                            @media (min-width:376px) and (max-width: 400px) {
                                font-size: 15px;
                            }

                            @media (min-width:401px) and (max-width: 767px) {
                                font-size: 16px;
                            }


                        }
                    }
                }

                &.CnvtFrmGrp {
                    .form-control {
                        text-align: right;
                        padding-right: 50px;
                        @include Txt18-400();
                        font-weight: 600;

                        @media(max-width: 360px) {
                            font-size: 19px;
                        }

                        @media (min-width:361px) and (max-width: 375px) {
                            font-size: 19px;
                        }

                        @media (min-width:376px) and (max-width: 400px) {
                            font-size: 21px;
                        }

                        @media (min-width:401px) and (max-width: 767px) {
                            font-size: 22px;
                        }


                    }

                    .IptGrpH5 {
                        position: absolute;
                        left: 40px;
                        top: 10px;
                        font-size: 14px;
                        font-weight: 600;
                        color: $Prmy-color;

                        @media(max-width: 360px) {
                            font-size: 16px;
                        }

                        @media (min-width:361px) and (max-width: 375px) {
                            font-size: 17px;
                        }

                        @media (min-width:376px) and (max-width: 400px) {
                            font-size: 18px;
                        }

                        @media (min-width:401px) and (max-width: 767px) {
                            font-size: 19px;
                        }


                    }

                    h4 {
                        @include Txt14-400();
                        margin-top: 0.25rem;
                        text-align: right;
                        font-family: var(--font-Nunito);
                        font-weight: 600;

                        @media(max-width: 360px) {
                            font-size: 13px;
                        }

                        @media (min-width:361px) and (max-width: 375px) {
                            font-size: 14px;
                        }

                        @media (min-width:376px) and (max-width: 400px) {
                            font-size: 15px;
                        }

                        @media (min-width:401px) and (max-width: 767px) {
                            font-size: 16px;
                        }


                    }
                }
            }

            h5 {
                font-size: 14px;
                line-height: 1.600000023841858;
                color: $secondary-color;
                text-align: center;
                margin-bottom: 0;
                font-family: var(--font-Nunito);
                font-weight: 400;

                @media(max-width: 360px) {
                    font-size: 12px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 13px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 14px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 15px;
                }


            }
        }

        .DeptFdsTrm {
            border-radius: 16px;
            background: #fafafa;
            padding: 1.5rem;
            border: 1px solid #e9e9e9;
            margin-bottom: 2rem;

            @media(max-width: 767px) {
                padding: 1rem;
                margin-bottom: 1rem;
            }

            h6 {
                @include Txt18-400();
                margin-bottom: 1rem;
                @include Flx-al-cen();
                justify-content: center;
                font-family: var(--font-Nunito);
                font-weight: 600;

                @media(max-width: 360px) {
                    font-size: 13px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 14px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 15px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 16px;
                }


                @media(max-width: 767px) {
                    margin-bottom: 0.5rem;
                }
            }

            p {
                font-size: 14px;
                font-weight: 300;
                line-height: 1.600000023841858;
                text-align: center;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                font-family: var(--font-SegoeRegular);

                @media(max-width: 360px) {
                    font-size: 12px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 13px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 14px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 15px;
                }


            }
        }
    }
}

.nodata {
    @include Txt16-500();
    color: #848EA0;
    text-align: center;
}

.DeptCnfmDv {
    border-radius: 16px;
    background: $text-white;
    padding: 1.5rem;
    margin-bottom: 1rem;

    @media(max-width:991px) {
        padding: 1rem;
    }

    .DeptCnfmDvDts {
        @include flx-al-btwn-wrp();
        margin-bottom: 1rem;

        span {
            @include Txt14-400();
            line-height: 1.2000000476837158;
            color: $secondary-color;

            &.Vl {
                color: $Prmy-color;

                img {
                    filter: invert(81%) sepia(79%) saturate(5279%) hue-rotate(225deg) brightness(96%) contrast(95%);
                }
            }

            &.Tit {
                @include Txt16-500();
            }

            &.text-suc {
                color: $text-green;
            }

            &.text-dgr {
                color: #FF103B;
            }
        }
    }

}

.ArbtMineInvt {
    @media(max-width:991px) {
        padding: 1rem;
    }

    .ArbtMineInvt {
        padding: 1.5rem 2rem;
        border-radius: 16px;
        background: $text-white;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05999999865889549);
        min-height: 235px;
        margin-bottom: 1rem;
        overflow: hidden;
        position: relative;

        @media(max-width: 767px) {
            padding: 16px;
        }

        @media(max-width: 991px) {
            box-shadow: none;
            border: 1px solid #d8d8d8;
        }

        &.ArbtMineInvt1 {
            z-index: 1;

            &:after {
                content: '';
                position: absolute;
                background: url(../assets/images/trd1.png) no-repeat;
                bottom: -2px;
                right: 30px;
                width: 271px;
                height: 137px;
                z-index: 0;
            }
        }

        &.ArbtMineInvt2 {
            &:after {
                content: '';
                position: absolute;
                background: url(../assets/images/trd2.png) no-repeat;
                bottom: 0;
                right: 30px;
                width: 324px;
                height: 155px;
                z-index: 0;
            }
        }

        &.ArbtMineInvt3 {
            &:after {
                content: '';
                position: absolute;
                background: url(../assets/images/refer.svg) no-repeat;
                bottom: 0;
                right: 30px;
                width: 331px;
                height: 140px;
                z-index: 0;

            }
        }

        h5 {
            @include Txt14-400();
            line-height: 1.2000000476837158;
            color: $text-blue;
            font-family: var(--font-Nunito);
            font-weight: 700;
            margin-bottom: 0.25rem;

            @media(max-width: 360px) {
                font-size: 14px;
            }

            @media (min-width:361px) and (max-width: 375px) {
                font-size: 15px;
            }

            @media (min-width:376px) and (max-width: 400px) {
                font-size: 16px;
            }

            @media (min-width:401px) and (max-width: 767px) {
                font-size: 17px;
            }


        }

        h4 {
            font-size: clamp(18px, 1.83vw, 22px);
            line-height: 1.2999999523162842;
            font-family: var(--font-Nunito);
            font-weight: 600;

            @media(max-width: 360px) {
                font-size: 17px;
            }

            @media (min-width:361px) and (max-width: 375px) {
                font-size: 18px;
            }

            @media (min-width:376px) and (max-width: 400px) {
                font-size: 19px;
            }

            @media (min-width:401px) and (max-width: 767px) {
                font-size: 20px;
            }


        }

        .DeptCpyDv {
            margin: 1.5rem 0;

            .form-control {
                height: 46px;
                background: $btn-graybg;
                border-radius: 100px;
                font-weight: 300;
                color: $secondary-color;
                border: 0;
                margin: 0.25rem 0;
            }

            .CpyBtn {
                border-radius: 100px;
                font-weight: 400;
                margin: 0.25rem 0;
            }
        }
    }
}

.MyCntrctDv {
    h2 {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.2000000476837158;
        margin-bottom: 1.5rem;
        color: $Prmy-color;
    }

    .MyCntrctDts {
        @include flx-al-btwn-wrp();
        padding: 15px 0;
        border-bottom: 1px solid #ebebeb;
        cursor: pointer;

        .MyCntrctDtsL {
            @include Flx-al-cen();

            h4 {
                @include Txt14-500();
                line-height: 1.2000000476837158;

                span {
                    color: $secondary-color;
                }
            }

            h5 {
                @include Txt14-400();
                color: $secondary-color;
                margin-bottom: 0;

                .text-suc {
                    color: $text-green;
                }

                .text-dgr {
                    color: #F54822;
                }
            }
        }
    }
}

.MyCntrctDvDts {
    h4 {
        @include Txt16-500();
        line-height: 1.2000000476837158;
        margin-bottom: 1.5rem;

        span {
            color: $secondary-color;
            @include Txt14-400();
        }
    }

    .MyCntrctDvDtsIr {
        @include flx-al-btwn-wrp();
        margin-bottom: 1rem;

        span {
            @include Txt14-400();
            line-height: 1.2000000476837158;

            &.Vl {
                color: $secondary-color;
            }

            &.text-suc {
                color: $text-green;
            }
        }
    }
}

.HstngTbs {
    .nav-tabs {
        width: auto !important;

        @media(max-width: 420px) {
            height: auto !important;
            padding: 0.5rem !important;
        }
    }
}

.ArbRgePg {
    h6 {
        font-size: 22px;
        font-weight: 400;
        line-height: 1.2000000476837158;
        text-align: center;
    }

    h2 {
        font-size: clamp(24px, 2.67vw, 32px);
        font-weight: 600;
        line-height: 1.2000000476837158;
        text-align: center;
        color: $text-white;
    }

    .HstOrdBtn {
        min-width: 169px;
        min-height: 48px;
        border-radius: 6px;
        background: $text-white;
        @include btn-flx-center();
        margin: auto;

        &:hover {
            background: $btn-graybg;
            @include trans04s();
        }
    }

    .ArbRgeIntroVl {
        margin-top: 1rem;

        .ArbRgeIntroVlDts {
            border-radius: 16px;
            background: $text-white;
            box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05999999865889549);
            min-height: 214px;
            padding: 1.5rem;
            margin-bottom: 1rem;

            h6 {
                line-height: 1.399999976158142;
                @include Txt14-400();
                color: $secondary-color;
            }

            .ArbRgeIntroVlDtsL {
                @include Flx-al-cen();
                flex-wrap: wrap;
                justify-content: space-around;
                padding: 60px 0;

                h5 {
                    line-height: 1.399999976158142;
                    @include Txt14-400();
                }
            }

            .ArbRgeIntroVlDtsR {
                @include flx-al-btwn-wrp();

                a {
                    @include Txt16-500();
                }
            }
        }

    }

    .ArbRgePrdDv {
        h3 {
            font-size: 20px;
            font-weight: 500;
            line-height: 1.2000000476837158;
            margin-bottom: 1rem;
        }

        .ArbRgePrdDvDts {
            border-radius: 16px;
            background: $text-white;
            box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05999999865889549);
            padding: 1.5rem 1.5rem 0rem;
            margin-bottom: 1rem;

            .ArbRgePrdDvDtsTp {
                @include Flx-al-cen();

                .DaysBtn {
                    border-radius: 6px;
                    background: linear-gradient(-98.57deg, #1652f0 47.78527021408081%, #73ccff 100%);
                    box-shadow: 0px 4px 15px 0px rgba(22, 82, 240, 0.30000001192092896);
                    min-width: 86px;
                    min-height: 34px;
                    @include btn-flx-center();
                    @include Txt14-500();
                    color: $text-white;
                    border: 0;

                    &:hover {
                        background: linear-gradient(-98.57deg, #73ccff 47.78527021408081%, #1652f0 100%);
                        @include trans04s();
                    }
                }

                h4 {
                    @include Txt16-400();
                    margin-bottom: 0;
                }
            }

            .ArbRgePrdDvDtsBtm {
                padding: 2rem 0;
                @include flx-al-btwn-wrp();

                h5 {
                    @include Txt14-400();
                    color: $secondary-color;
                }

                h6 {
                    @include Txt14-400();
                    text-align: left;
                }
            }
        }
    }
}

.JnArbRgePg {
    .JnArbClsIg {
        margin-bottom: 1.5rem;

        @media(max-width: 767px) {
            width: 24px;
            margin-bottom: 0.5rem;
        }

    }

    .JnArbRgeTp {
        border-radius: 16px;
        padding: 2rem;
        margin: 1rem 0;
        position: relative;

        @media(max-width:767px) {
            min-height: auto;
            padding: 1rem;
            margin: 0;
        }

        &:after {
            content: '';
            position: absolute;
            background: url(../assets/images/ai.png) no-repeat;
            width: 242px;
            height: 242px;
            right: 30px;
            top: 0;

            @media(max-width: 767px) {
                display: none;
            }

        }

        a {
            @include Txt24-400();
            line-height: 20px;
            color: $text-blue;
            display: block;

            @media(max-width: 767px) {
                font-size: 14px;
            }
        }

        h2 {
            font-size: clamp(24px, 3.33vw, 40px);
            font-weight: 600;
            line-height: 1.2000000476837158;
            padding: 0.5rem 0 0;
            margin-bottom: 0;
            font-family: var(--font-Nunito);

            @media(max-width: 360px) {
                font-size: 17px;
            }

            @media (min-width:361px) and (max-width: 375px) {
                font-size: 18px;
            }

            @media (min-width:376px) and (max-width: 400px) {
                font-size: 19px;
            }

            @media (min-width:401px) and (max-width: 767px) {
                font-size: 20px;
            }


            &.JAIArbH2 {
                font-family: var(--font-Nunito);
                font-weight: 700;

                @media(max-width: 360px) {
                    font-size: 13px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 14px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 15px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 16px;
                }


            }
        }

        h6 {
            @include Txt14-400();
            line-height: 1.399999976158142;
            font-family: var(--font-Nunito);
            font-weight: 600;

            @media(max-width: 360px) {
                font-size: 15px;
            }

            @media (min-width:361px) and (max-width: 375px) {
                font-size: 16px;
            }

            @media (min-width:376px) and (max-width: 400px) {
                font-size: 17px;
            }

            @media (min-width:401px) and (max-width: 767px) {
                font-size: 18px;
            }


        }

        .JnArbRgeTpInr {
            position: relative;
            @include flx-al-btwn();

            a {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                font-family: var(--font-Nunito);
                font-weight: 600;
                color: $Prmy-color;

                @media(max-width: 360px) {
                    font-size: 17px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 18px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 19px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 20px;
                }


            }
        }
    }

    .JnArbRgeDur {
        border-radius: 16px;
        background: $text-white;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05999999865889549);
        padding: clamp(24px, 3.67vw, 44px);

        @media(max-width: 767px) {
            padding: 16px;
        }

        .JnArbRgeDurDy {
            @include flx-al-btwn-wrp();
            justify-content: space-around;
            margin-bottom: 2rem;

            @media(max-width: 767px) {
                margin-bottom: 1rem;
            }

            h4 {
                font-size: 20px;
                line-height: 1.2000000476837158;
                margin-bottom: 0;
                font-family: var(--font-Nunito);
                font-weight: 600;

                @media(max-width: 360px) {
                    font-size: 17px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 18px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 19px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 20px;
                }


            }

            .DaysBtn {
                border-radius: 6px;
                background: linear-gradient(-98.57deg, #1652f0 47.78527021408081%, #73ccff 100%);
                box-shadow: 0px 4px 15px 0px rgba(22, 82, 240, 0.30000001192092896);
                min-width: 86px;
                min-height: 34px;
                @include btn-flx-center();
                @include Txt14-500();
                color: $text-white;
                border: 0;
                font-family: var(--font-Nunito);
                font-weight: 700;

                @media(max-width: 360px) {
                    font-size: 13px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 14px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 15px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 16px;
                }


                @media(max-width: 767px) {
                    height: 30px;
                }

                &:hover {
                    background: linear-gradient(-98.57deg, #73ccff 47.78527021408081%, #1652f0 100%);
                    @include trans04s();
                }
            }
        }
    }

    .JnArbRgeCnTyps {
        .JnArbRgeCnTypsCt {
            border-radius: 16px;
            background: $text-white;
            box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05999999865889549);
            padding: 1.5rem;
            margin-bottom: 2rem;

            @media(max-width: 767px) {
                padding: 1rem;
                margin-bottom: 1rem;
            }

            p {
                @include Txt14-400();
                line-height: 1.399999976158142;
                color: $secondary-color;
                margin-bottom: 0.5rem;

                @media(max-width: 767px) {
                    font-size: 13px;
                }
            }

            h3 {
                @include Flx-al-cen();
                @include Txt14-400();
                line-height: 1.399999976158142;
                color: $secondary-color;
                margin-bottom: 1.5rem;

                @media(max-width: 767px) {
                    margin-bottom: 1rem;

                    img {
                        width: 24px;
                    }
                }
            }

            .JnArbRgeCnTypsFlx {
                @include Flx-al-cen();
                justify-content: space-around;
            }

            .JnArbRgeCnTypsVl {
                margin-bottom: 1rem;
                text-align: center;

                h5 {
                    font-size: 14px;
                    line-height: 1.399999976158142;
                    color: $secondary-color;
                    font-family: var(--font-Nunito);
                    font-weight: 600;

                    @media(max-width: 360px) {
                        font-size: 15px;
                    }

                    @media (min-width:361px) and (max-width: 375px) {
                        font-size: 16px;
                    }

                    @media (min-width:376px) and (max-width: 400px) {
                        font-size: 17px;
                    }

                    @media (min-width:401px) and (max-width: 767px) {
                        font-size: 18px;
                    }


                    @media(max-width: 767px) {
                        margin-bottom: 0.25rem;
                    }
                }

                h6 {
                    @include Txt14-400();
                    line-height: 1.399999976158142;
                    font-weight: 500;

                    @media(max-width: 360px) {
                        font-size: 13px;
                    }

                    @media (min-width:361px) and (max-width: 375px) {
                        font-size: 14px;
                    }

                    @media (min-width:376px) and (max-width: 400px) {
                        font-size: 15px;
                    }

                    @media (min-width:401px) and (max-width: 767px) {
                        font-size: 16px;
                    }


                    @media(max-width: 767px) {

                        margin-bottom: 0.25rem;
                    }
                }
            }

            .RngSldrDiv {
                margin: 1.5rem 0 2rem;

                @media(max-width: 767px) {
                    margin: 1.5rem 0;
                }
            }

            .CmnBtn {
                margin: 1.5 0;

                @media(max-width: 767px) {
                    margin: 0 0 0.5rem;
                }
            }

            .form-control {
                border-radius: 6px;
                background: #f4f4f4;
                border: 0;
                font-size: 14px;
                font-weight: 300;
                line-height: 1.399999976158142;
                color: $secondary-color;
            }

            .IptGrpDv {
                position: relative;

                .IPtGrpIg {
                    position: absolute;
                    left: 10px;
                    top: 12px;
                }

                .IptGrpH5 {
                    position: absolute;
                    right: 20px;
                    top: 15px;
                    font-size: 14px;

                    color: $Prmy-color;
                    font-family: var(--font-Nunito);
                    font-weight: 600;
                }

                .form-control {
                    padding-left: 40px;
                }

                .IptGrpCnLmt {
                    @include Flx-al-cen();
                    position: absolute;
                    right: 15px;
                    top: 12px;

                    h6 {
                        @include Txt14-400();
                        color: #99A1B0;
                        margin-bottom: 0;
                        margin-right: 5px;
                    }

                    a {
                        color: $secondary-color;
                    }
                }
            }

            .range-slider {
                height: 2px;
                background: #EDEDF0;

                .range-slider__thumb {
                    border: 2px solid $btn-bg;
                    width: 8px;
                    height: 8px;
                    background: #fff;
                }

                .range-slider__range {
                    background: $btn-bg;
                }
            }
        }

        .JnArbRgeRls {
            border-radius: 16px;
            background: #fafafa;
            padding: 1.5rem;

            @media(max-width:767px) {
                padding: 1rem;
            }

            p {
                font-size: 14px;
                font-weight: 300;
                line-height: 1.7000000476837158;
                @include Flx-al-cen();
                margin-bottom: 0.25rem;
                font-family: var(--font-Nunito);

                @media(max-width: 360px) {
                    font-size: 13px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 14px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 15px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 16px;
                }


            }
        }
    }
}

.MinePg {
    .MineTp {
        position: relative;
        margin-bottom: 1rem;

        &:after {
            content: '';
            position: absolute;
            background: url(../assets/images/lease-machine.png);
            width: 197px;
            height: 243px;
            right: 75px;
            top: 35px;

            @media(max-width: 767px) {
                display: none;
            }
        }

        h1 {
            margin-top: 50px;
        }

        p {
            margin-top: 0.5rem !important;
        }

        .MineTpIG {
            @include Flx-al-cen();
            margin-top: 47px;
        }
    }

    .ArbRgeIntroVl {
        margin-top: 1rem;

        .ArbRgeIntroVlDts {
            border-radius: 16px;
            background: $text-white;
            box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05999999865889549);
            min-height: 214px;
            padding: 1.5rem;
            margin-bottom: 1rem;

            h6 {
                line-height: 1.399999976158142;
                @include Txt14-400();
                color: $secondary-color;
            }

            .ArbRgeIntroVlDtsL {
                @include Flx-al-cen();
                flex-wrap: wrap;
                justify-content: space-around;
                padding: 60px 0;

                h5 {
                    line-height: 1.399999976158142;
                    @include Txt14-400();
                }
            }

            .ArbRgeIntroVlDtsR {
                @include flx-al-btwn-wrp();

                a {
                    @include Txt16-500();
                }
            }
        }

    }

    .ArbRgePrdDvDts {
        border-radius: 16px;
        background: $text-white;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05999999865889549);
        padding: 1.5rem 1.5rem 0rem;
        margin-bottom: 1rem;

        .ArbRgePrdDvDtsTp {
            @include Flx-al-cen();

            .DaysBtn {
                border-radius: 6px;
                background: linear-gradient(-98.57deg, #1652f0 47.78527021408081%, #73ccff 100%);
                box-shadow: 0px 4px 15px 0px rgba(22, 82, 240, 0.30000001192092896);
                min-width: 86px;
                min-height: 34px;
                @include btn-flx-center();
                @include Txt14-500();
                color: $text-white;
                border: 0;

                &:hover {
                    background: linear-gradient(-98.57deg, #73ccff 47.78527021408081%, #1652f0 100%);
                    @include trans04s();
                }
            }

            h4 {
                @include Txt16-400();
                margin-bottom: 0;
            }
        }

        .ArbRgePrdDvDtsBtm {
            padding: 2rem 0;
            @include flx-al-btwn-wrp();

            h5 {
                @include Txt14-400();
                color: $secondary-color;
            }

            h6 {
                @include Txt14-400();
                text-align: left;
            }
        }
    }

    .MineRent {
        @include flx-al-btwn-wrp();
        padding: 40px 0;

        h5 {
            @include Txt14-400();
            line-height: 1.399999976158142;
        }

        h6 {
            @include Txt14-400();
            line-height: 1.399999976158142;
            color: $secondary-color;
        }
    }
}

.MineDtsPg {
    margin-top: 1rem;

    .MineDtsTp {
        background: $btn-bg;
        border-radius: 16px;
        padding: clamp(24px, 3.67vw, 44px);
        position: relative;
        min-height: 276px;
        margin-bottom: 1rem;

        &:after {
            content: '';
            position: absolute;
            background: url('../assets/images/leasehold-mine.png');
            width: 254px;
            height: 245px;
            top: 20px;
            right: 75px;

            @media(max-width: 767px) {
                display: none;
            }
        }

        h2 {
            @include Txt24-500();
            color: $text-white;
            margin-top: 50px;
        }
    }

    .JnArbRgeDur {
        border-radius: 16px;
        background: $text-white;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05999999865889549);
        padding: clamp(24px, 3.67vw, 44px);

        .MineVer {
            @include flx-al-btwn-wrp();
            margin-bottom: 2rem;

            h4 {
                font-size: 20px;
                font-weight: 500;
                line-height: 1.2000000476837158;
            }

            h5 {
                @include Txt14-400();
                line-height: 1.399999976158142;
                color: $secondary-color;
            }

            .MineIncDec {
                width: 100px;
                height: 40px;
                background: $btn-graybg;
                @include flx-al-btwn();
                border-radius: 6px;

                span {
                    @include Txt15-500();
                    line-height: 1.2000000476837158;
                    color: $secondary-color;
                    display: block;
                    margin: auto;

                    &.Vl {
                        width: 24px;
                        height: 24px;
                        background: $text-white;
                        border-radius: 3px;
                        @include btn-flx-center();
                        color: $text-blue;
                    }
                }
            }
        }

        .JnArbRgeCnTyps {
            .JnArbRgeCnTypsCt {
                border-radius: 16px;
                background: $text-white;
                box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05999999865889549);
                padding: 1.5rem;
                margin-bottom: 2rem;

                @media(max-width:767px) {
                    margin-bottom: 1rem;
                }

                .MineIntro {
                    @include Txt16-500();
                }

                p {
                    @include Txt14-400();
                    line-height: 1.399999976158142;
                    color: $secondary-color;
                    margin-bottom: 0.5rem;
                }

                h3 {
                    @include Flx-al-cen();
                    @include Txt14-400();
                    line-height: 1.399999976158142;
                    color: $secondary-color;
                    margin-bottom: 1.5rem;
                }

                .JnArbRgeCnTypsVl {
                    @include flx-al-btwn-wrp();
                    margin-bottom: 1rem;

                    h5 {
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 1.399999976158142;
                        color: $secondary-color;
                    }

                    h6 {
                        @include Txt14-400();
                        line-height: 1.399999976158142;
                    }
                }

                .form-control {
                    border-radius: 6px;
                    background: #f4f4f4;
                    border: 0;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 1.399999976158142;
                    color: $secondary-color;
                }

                .IptGrpDv {
                    position: relative;

                    .IPtGrpIg {
                        position: absolute;
                        left: 10px;
                        top: 12px;
                    }

                    .IptGrpH5 {
                        position: absolute;
                        right: 20px;
                        top: 15px;
                        font-size: 14px;
                        font-weight: 400;
                        color: $Prmy-color;
                    }

                    .form-control {
                        padding-left: 40px;
                    }

                    .IptGrpCnLmt {
                        @include Flx-al-cen();
                        position: absolute;
                        right: 15px;
                        top: 12px;

                        h6 {
                            @include Txt14-400();
                            color: #99A1B0;
                            margin-bottom: 0;
                            margin-right: 5px;
                        }

                        a {
                            color: $secondary-color;
                        }
                    }
                }
            }

            .JnArbRgeRls {
                border-radius: 16px;
                background: #fafafa;
                padding: 1.5rem;
                margin-bottom: 2rem;

                p {
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 1.7000000476837158;
                    @include Flx-al-cen();
                    margin-bottom: 0.75rem;
                }
            }

            .LseNw {
                @include flx-al-btwn-wrp();
                justify-content: space-around;
                flex-wrap: wrap;
                border-radius: 6px;
                background: $btn-bg;
                min-height: 46px;
                padding: 0 1rem;
                position: relative;

                &:after {
                    content: '|';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    color: $text-white;

                    @media(max-width:767px) {
                        display: none;
                    }
                }

                .LseNwL {
                    @include Flx-al-cen();
                }

                h5 {
                    @include Txt16-500();
                    color: $text-white;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.ArbRgeDtsPg {
    .ArbRgeDtsPgTp {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            background: url(../assets/images/mine-intro.png);
            width: 348px;
            height: 178px;
            right: 50px;
            top: 45px;
            display: none;
        }
    }

    .ArbRgeDtsPgRls {
        h4 {
            @include Txt16-500();
            line-height: 20px;
            margin-bottom: 1.5rem;
            color: $text-blue;
            font-family: var(--font-Nunito);

            @media(max-width: 360px) {
                font-size: 19px;
            }

            @media (min-width:361px) and (max-width: 375px) {
                font-size: 20px;
            }

            @media (min-width:376px) and (max-width: 400px) {
                font-size: 21px;
            }

            @media (min-width:401px) and (max-width: 767px) {
                font-size: 22px;
            }


        }

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 1.7999999523162842;
            color: $secondary-color;
            margin-bottom: 1.5rem;
            font-family: var(--font-Nunito);

            @media(max-width: 360px) {
                font-size: 15px;
            }

            @media (min-width:361px) and (max-width: 375px) {
                font-size: 16px;
            }

            @media (min-width:376px) and (max-width: 400px) {
                font-size: 17px;
            }

            @media (min-width:401px) and (max-width: 767px) {
                font-size: 18px;
            }

        }
    }
}

.SmtLnPg {
    .SmtLnTp {
        position: relative;
        min-height: 276px;

        @media(max-width:767px) {
            min-height: auto;
        }

        &:after {
            content: '';
            position: absolute;
            background: url(../assets/images/smart-loan.png);
            width: 293px;
            height: 220px;
            right: 60px;
            top: 40px;

            @media(max-width:767px) {
                display: none;
            }
        }

        &.DfltTp {
            background: #fff;

            h1 {
                color: $Prmy-color;
                font-family: var(--font-Nunito);
                font-weight: 600;

                @media(max-width: 360px) {
                    font-size: 19px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 20px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 21px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 22px;
                }


            }

            p {
                color: $secondary-color;
                font-family: var(--font-Nunito);
                font-weight: 600;

                @media(max-width: 360px) {
                    font-size: 13px;
                }

                @media (min-width:361px) and (max-width: 375px) {
                    font-size: 14px;
                }

                @media (min-width:376px) and (max-width: 400px) {
                    font-size: 15px;
                }

                @media (min-width:401px) and (max-width: 767px) {
                    font-size: 16px;
                }

            }
        }
    }

    .DeptDfltBg {
        border-radius: 16px;
        background: $text-white;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05999999865889549);
        padding: 1.5rem;
        margin-top: 1rem;
        min-height: 658px;

        @media(max-width: 767px) {
            margin-top: 0.25rem;
            min-height: auto;
            padding: 1rem;

            .form-group {
                margin-bottom: 0.5rem;
            }
        }

        h3 {
            margin: 2rem 0 1.5rem;
            font-size: 22px;
            font-weight: 500;
            line-height: 1.2000000476837158;
            color: $Prmy-color;
            text-align: center;
            font-family: var(--font-SegoeRegular);

            @media(max-width: 767px) {
                margin: 0rem 0 1rem;
                font-size: 18px;
                line-height: 1.4;
            }
        }

        .DeptFdsDvCnt {
            border-radius: 16px;
            background: $text-white;
            box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05999999865889549);
            padding: 1.5rem;
            margin-bottom: 2rem;

            @media(max-width:767px) {
                padding: 1rem;
                margin-bottom: 1rem;
            }

            span {
                width: 82px;
                height: 40px;
                background: $btn-graybg;
                border-radius: 100px;
                @include btn-flx-center();
                margin: auto;
                margin-bottom: 1rem;
                font-weight: 300;
                color: $secondary-color;
            }

            .DeptFdsDvCntFrmGrp {
                label {
                    font-size: 14px;
                    font-family: var(--font-Nunito);

                    @media(max-width:767px) {
                        font-size: 13px;
                    }
                }

                .form-control {
                    border-radius: 6px;
                    background: #f4f4f4;
                    border: 0;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 1.399999976158142;
                    color: $secondary-color;
                    font-family: var(--font-Nunito);
                }

                .IptGrpDv {
                    position: relative;

                    .IptGrpCnLmt {
                        @include Flx-al-cen();
                        position: absolute;
                        right: 15px;
                        top: 12px;

                        a {
                            color: $text-blue;
                        }
                    }
                }

                .dropdown {
                    button {
                        width: 100%;
                        border-radius: 6px;
                        background: #f4f4f4;
                        border: 0;
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 1.399999976158142;
                        color: $secondary-color;
                    }

                    .dropdown-menu {
                        width: 100%;
                        padding: 0;

                        .dropdown-item {
                            span {
                                background: transparent;
                            }

                            &:hover {
                                background: $text-white;
                                @include trans04s();
                            }
                        }
                    }
                }

                .CmnBtn {
                    margin: 2rem 0;

                    @media(max-width:767px) {
                        margin: 1.5rem 0 1rem;
                    }
                }
            }
        }
    }
}

.NotfifyPg {
    .NotfifyBg {
        border-radius: 16px;
        background: $text-white;
        padding: 44px;

        @media(max-width:991px) {
            padding: 16px;
        }

        h3 {
            font-size: 18px;
            font-weight: 500;
            line-height: 1.2000000476837158;
            margin-bottom: 1.5rem;

            @media(max-width:767px) {
                font-size: 15px;
                margin-bottom: 1rem;
            }
        }

        .NotfifyInrDts {
            border-radius: 8px;
            background: #f5f5f5;
            padding: 0.5rem;
            @include flx-al-btwn-wrp();
            margin-bottom: 0.5rem;

            &.NotifyRead {
                background: transparent;

                .grndt {
                    display: none
                }
            }

            .NotfifyInrDtsL {
                h4 {
                    @include Flx-al-cen();
                    flex-wrap: wrap;
                    @include Txt16-500();
                    margin-bottom: 0.75rem;

                    .lnRej {
                        color: $btn-redbg;
                        font-weight: 500;
                    }

                    .lnAct {
                        color: $text-green;
                        font-weight: 500;
                    }

                    span {
                        @include Txt14-400();
                        color: $secondary-color;
                        margin-bottom: 0;
                        text-align: left;

                        @media(max-width:767px) {
                            font-size: 13px;
                        }

                    }
                }

                h5 {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 1.2000000476837158;
                    color: $secondary-color;
                    margin-bottom: 0.5rem;
                }
            }
        }

        .DeptTbs {
            margin-top: 2rem;

            @media(max-width:767px) {
                margin-top: 1rem;
            }

            .nav-tabs {
                display: inline-flex;
                justify-content: center;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                padding: 0 0.25rem;
                height: 48px;
                align-items: center;
                border-radius: 6px;
                background: #f5f6f8;
                border-bottom: 0;
                width: 320px;


                .nav-item {
                    .nav-link {
                        height: 38px;
                        border-radius: 6px;
                        @include Txt14-400();
                        color: $Prmy-color;
                        margin-bottom: 0;
                        @include btn-flx-center();
                        cursor: pointer;
                        min-width: 97px;
                        font-family: var(--font-SegoeBold);

                        @media(max-width: 360px) {
                            font-size: 12px;
                        }

                        @media (min-width:361px) and (max-width: 375px) {
                            font-size: 13px;
                        }

                        @media (min-width:376px) and (max-width: 400px) {
                            font-size: 14px;
                        }

                        @media (min-width:401px) and (max-width: 767px) {
                            font-size: 15px;
                        }


                        &:hover,
                        &.active {
                            background: $btn-bg;
                            color: $text-white;
                            @include trans04s();
                        }
                    }
                }
            }
        }
    }
}

.ChatPg {
    .ChatTp {
        background: #fff;
        margin-bottom: 1rem;

        @media(max-width: 767px) {
            margin-bottom: 0;
        }

        h4 {
            font-size: 22px;
            font-weight: 500;
            line-height: 1.2000000476837158;
            color: $Prmy-color;

            @media(max-width: 767px) {
                font-size: 16px;
            }
        }
    }

    .ChatBg {
        border-radius: 16px;
        background: $text-white;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05999999865889549);
        padding: 1rem;

        .ChatInrTp {
            border-radius: 16px;
            background: #ebeaea;
            @include Flx-al-cen();
            padding: 0.5rem;
            margin-bottom: 1.5rem;

            @media(max-width:767px) {
                margin-bottom: 1rem;
            }

            h5 {
                @include Txt18-400();
                font-weight: 500;
                margin-bottom: 0;
                ;
            }
        }

        .ChatDts.scroller {
            height: calc(100vh - 165px - 54px);
            overflow-y: scroll;

            .ChatDtsL {
                margin-bottom: 0.5rem;

                .ChatDtsCtBg {
                    border-radius: 6px;
                    background: #EAFFE0;
                    min-height: 40px;
                    padding: 0.5rem 1rem;
                    @include Flx-al-cen();
                    position: relative;

                    .polylft {
                        position: absolute;
                        left: -12px
                    }

                    span {
                        @include Txt13-400();
                    }
                }

                p {
                    @include Flx-al-cen();
                    color: #807e96;
                    margin-bottom: 0;
                    font-size: 11px;
                }

                .ChatDtsCt {
                    padding-top: 5px;
                    padding-left: 50px;
                }
            }

            .ChatDtsR {
                @include Flx-al-cen();
                justify-content: flex-end;
                margin-bottom: 0.5rem;
                margin-right: 0.5rem;

                .ChatDtsCtBg {
                    border-radius: 6px;
                    background: #f5f5f5;
                    min-height: 40px;
                    padding: 0.5rem 1rem;
                    @include Flx-al-cen();
                    position: relative;

                    .polyrgt {
                        position: absolute;
                        right: -12px;
                    }

                    span {
                        @include Txt13-400();
                        word-break: break-all;
                    }
                }

                p {
                    @include Flx-al-cen();
                    color: #807e96;
                    margin-bottom: 0;
                    text-align: right;
                    display: block;
                    font-size: 11px;
                }

                .ChatDtsCt {
                    padding-top: 5px;
                    padding-right: 45px;
                }
            }
        }

        .ChatSndDv {
            border-radius: 12px;
            background: #ebebeb;
            @include flx-al-btwn();
            min-height: 77px;
            padding: 0.5rem;
            position: relative;
            bottom: -6px;

            .ChatSndDvAth {
                width: 28px;
                height: 28px;
                margin-right: 0.5rem;
                position: relative;

                .ChtIg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 28px;
                    height: 28px;
                }
            }

            .form-control {
                border-radius: 6px;
                background: $text-white;
                font-size: 14px;
                font-weight: 300;
                line-height: 1.2000000476837158;
                color: $secondary-color;
                padding-right: 50px;
            }

            .emojiIg {
                position: absolute;
                right: 70px;
            }


        }

        .cntbtncmn {
            width: 100%;
            min-height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            background: -webkit-linear-gradient(135deg, #fff, #e0e0e0, #fff);
            background: linear-gradient(-45deg, #fff, #e0e0e0, #fff);
            background-size: 400% 400%;
            -webkit-animation: gradientBG 6s ease infinite;
            animation: gradientBG 6s ease infinite;
            border-radius: 4px;
            font-size: 14px;
            color: #000000;


        }
    }
}

@keyframes gradientBG {
    0% {
        background-position: 0 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }
}

.RefrlTp {

    background: transparent !important;

    @media(max-width:767px) {}

    h4 {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.2000000476837158;
        color: $Prmy-color;
    }
}

.RefrlBg {
    border-radius: 16px;
    background: $text-white;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05999999865889549);
    padding: clamp(22px, 3.67vw, 44px);

    .RefrlIncVal {
        margin-bottom: 1rem;

        @media(max-width: 767px) {
            margin-bottom: 0.5rem;
        }

        @include btn-flx-center();

        h5 {
            @include Txt14-400();

            &.Vl {
                font-weight: 500;
                color: $text-blue;
                margin-left: 0.5rem;
            }
        }
    }

    h4 {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.2000000476837158;
        margin-bottom: 1.5rem;
        text-align: center;

        @media(max-width: 767px) {
            font-size: 15px;
            margin-bottom: 1rem;
        }
    }

    p {
        font-size: 13px;
        font-weight: 300;
        line-height: 1.600000023841858;
        margin-bottom: 1.5rem;
        text-align: center;

        @media(max-width: 767px) {
            margin-bottom: 1rem;
        }
    }

    .DeptCpyDv {
        margin: 1.5rem 0;

        .form-control {
            height: 46px;
            background: $btn-graybg;
            border-radius: 100px;
            font-weight: 300;
            color: $secondary-color;
            border: 0;
            margin: 0.25rem 0;
        }

        .CpyBtn {
            border-radius: 100px;
            font-weight: 400;
            margin: 0.25rem 0;
        }
    }
}


// toast 

.Toastify__toast--error {
    background: #000000 !important;
    color: #ffffff !important;
    font-weight: bold;
}

.Toastify__toast--info {
    background: #000000 !important;
    color: #ffffff !important;
    font-weight: bold;
}

.Toastify__toast--success {
    background: #000000 !important;
    color: #ffffff !important;
    font-weight: bold;
}

.chatCls {
    position: relative;
    width: 100%;
    z-index: 99;

    aside.epr-main {
        position: absolute;
        bottom: 0;
    }
}

.NoRrdFnd {
    min-height: 500px;
    @include btn-flx-center();
    font-size: 20px;
    font-weight: 600;
}

.DfltNtfDv {
    position: relative;

    .DfltNtfCnt {
        position: absolute;
        font-size: 12px;
        font-weight: 600;
        width: 35px;
        height: 35px;
        background: #5584f5;
        color: $text-white;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -16px;
        right: -22px;
    }
}

.GnrtPinDgtVal {
    input {
        margin-right: 0.5rem;
        width: 50px !important;
        height: 50px;
        border-radius: 5px;
        border: 1px solid #eaeaea;
    }
}

.MrktTbsTd3 {
    text-align: right;
}

.NdtFnd {
    font-size: 14px;
    text-align: center;
    font-family: var(--font-Nunito);
    font-weight: 700;

    @media(max-width: 360px) {
        font-size: 19px;
    }

    @media (min-width:361px) and (max-width: 375px) {
        font-size: 20px;
    }

    @media (min-width:376px) and (max-width: 400px) {
        font-size: 21px;
    }

    @media (min-width:401px) and (max-width: 767px) {
        font-size: 22px;
    }


    img {
        width: 150px;
        display: block;
        margin: auto;
    }
}

.DfltTp.DeptPgTp {
    background: #ffffff;

    h4,
    h3 {
        color: $Prmy-color !important;
        font-weight: 600;

        @media(max-width: 360px) {
            font-size: 24px;
        }

        @media (min-width:361px) and (max-width: 375px) {
            font-size: 25px;
        }

        @media (min-width:376px) and (max-width: 400px) {
            font-size: 26px;
        }

        @media (min-width:401px) and (max-width: 767px) {
            font-size: 28px;
        }


    }

    p {
        color: $secondary-color;
        font-family: var(--font-SegoeRegular);
        margin-bottom: 0;

        @media(max-width: 360px) {
            font-size: 13px;
        }

        @media (min-width:361px) and (max-width: 375px) {
            font-size: 14px;
        }

        @media (min-width:376px) and (max-width: 400px) {
            font-size: 15px;
        }

        @media (min-width:401px) and (max-width: 767px) {
            font-size: 16px;
        }


    }
}

.WltBg {
    @media(max-width:450px) {
        width: 80px;
    }
}

.scroller {
    --sb-track-color: #d3d3d3;
    --sb-thumb-color: #d9d9d9;
    --sb-size: 2px;

    @media(max-width: 767px) {
        --sb-track-color: transparent;
        --sb-thumb-color: transparent;
    }
}

.scroller::-webkit-scrollbar {
    width: var(--sb-size)
}

.scroller::-webkit-scrollbar-track {}

.scroller::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 10px;
}

.RefCmsnDvIg {
    margin: 1rem 0 1.5rem;
}

.copyCls {
    text-align: center;
    color: $text-blue;
    font-weight: 600;
    cursor: pointer;
    word-break: break-all;
    font-size: 12px;
}

@media(max-width:767px) {
    .CmnBtnMb {
        margin: 1rem 0 !important;
    }

}

.tv-lightweight-charts table {
    @media(max-width:767px) {
        width: 576px !important;
        height: 400px !important;
    }

    @media(max-width:575px) {
        width: 440px !important;
        height: 400px !important;
    }

    @media(max-width:450px) {
        width: 420px !important;
        height: 400px !important;
    }

    @media(max-width:420px) {
        width: 400px !important;
        height: 400px !important;
    }

    @media(max-width:400px) {
        width: 380px !important;
        height: 400px !important;
    }

    @media(max-width:375px) {
        width: 340px !important;
        height: 400px !important;
    }

    @media(max-width:360px) {
        width: 340px !important;
        height: 400px !important;
    }
}

.lOCo86x8dfi3dTu9azLl {
    display: none;
}

.GdgAxmY91IHQbQMbQjIp {
    position: fixed !important;
    right: 100px !important;
    bottom: 30% !important;
}

.cryptlogo {
    width: 100px;
}

.tradingview-widget-container {
    .tradingview-widget-container__widget {
        @include btn-flx-center();
    }
}

.MxSpn {
    font-family: var(--font-SegoeRegular);

    @media(max-width: 360px) {
        font-size: 13px;
    }

    @media (min-width:361px) and (max-width: 375px) {
        font-size: 14px;
    }

    @media (min-width:376px) and (max-width: 400px) {
        font-size: 15px;
    }

    @media (min-width:401px) and (max-width: 767px) {
        font-size: 16px;
    }


}

label {
    font-family: var(--font-Nunito);
    font-weight: 600;
}

/* Landing Page CSS Start */

.LndPg {
    background: #1c65ff;
    min-height: 100vh;

    .LndPgInr {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        text-align: left;
        background: url(../assets/images/coinbase-bg.svg) top no-repeat;
        background-size: cover;

        .LndInrImgTp {
            margin: 3.46667vw 3.46667vw 0;

            h2 {
                font-size: 6.4vw;
                font-family: var(--font-Poppins);
                color: #fff;
                font-weight: 600;
                margin-bottom: 0;
                @include Flx-al-cen();

                .LndInrImgTpIg {
                    height: 12.8vw;
                    border-radius: 2.13333vw;
                    margin: 0 4.26667vw 0 0;
                }
            }

        }

        h1 {
            margin-top: 16vw;
            font-weight: 600;
            font-family: var(--font-Poppins);
            font-size: 8.53333vw;
            text-align: center;
            color: #fff;
        }

        h3 {
            margin-top: 6.13333vw;
            color: #fff;
            font-weight: 600;
            font-family: var(--font-Poppins);
            font-size: 4.26667vw;
            text-align: center;
        }

        .LndPgIntrLst {
            margin-top: 3.73333vw;
            color: #fff;

            ul {
                padding-left: 0;

                li {
                    list-style: none;
                    padding: 0 5.33333vw;
                    display: flex;
                    align-items: baseline;

                    img {

                        line-height: 6.4vw;
                        margin-right: 3.73333vw;
                        width: 3.36vw;
                        height: 2.4vw;
                    }

                    span {
                        line-height: 6.4vw;
                        margin-bottom: 0;
                        font-weight: 600;
                        font-family: var(--font-Poppins);
                        font-size: 3.6vw;
                    }
                }
            }
        }

        .LndPgIntrIg {
            margin-top: 12vw;
            max-height: 88vw;
            text-align: center;
            max-width: 100vw;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-height: 88vw;
                min-height: 57.33333vw;
                width: auto;
            }
        }

        .LndPgPlyStr {
            margin-top: 7.46667vw;
            padding-bottom: 2.66667vw;
            display: flex;
            justify-content: center;

            img {
                width: 37.33333vw;
                height: 11.46667vw;

                &:first-child {
                    margin-right: 6.66667vw;
                }
            }
        }
    }
}

/* Landing Page CSS End */

.DrBtmVal {
    .btn-primary {
        background: transparent;
        color: $Prmy-color;
        border-color: transparent;
        padding: 0;
        font-family: var(--font-Nunito);
        font-weight: 700;

        &:hover,
        &.active {
            color: #1652f0;
        }

        @media(max-width: 430px) {
            font-size: 14px;
        }
    }
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    background: transparent;
    border-color: transparent;
    color: #1652f0;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.DfltNtfCnt1 {
    background: #051041;
    position: absolute;
    font-size: 12px;
    font-weight: 600;
    width: 30px;
    height: 30px;
    color: $text-white;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -12px;
    right: -17px;
}

.PstVal {
    position: relative;
    z-index: 9;

    .form-control {
        z-index: 2;
    }

    .IptGrpCnLmt {
        position: absolute;
        right: 15px;
        top: 12px;
        z-index: 0;
    }

}



#tv-attr-logo {
    display: none !important;
}



.wdtitle {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.2000000477;
    color: var(--prmy-color);
    margin-bottom: 0;
}

.WdSucSec {
    position: relative;
    min-height: calc(100vh - 175px);

    h3 {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.2000000476837158;
        color: #353f52;
        margin: 35px 0 24px;
        text-align: center;
    }

    h4 {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.600000023841858;
        color: #2a3345;
        margin-bottom: 17px;
        text-align: center;
    }

    h5 {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.600000023841858;
        color: #353f52;
        text-align: center;
        margin-bottom: 0px;
    }

    a {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.2000000476837158;
        color: #353f52;
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
    }
}

// Sonar Qube Fixes
.mtBtn {
    width: 100%;
    border: none;
    padding: 0px;
    background: initial;
    color: inherit;
}

.mtBtnW {
    border: none;
    padding: 0px;
    background: initial;
    color: inherit;
}

.mtBtnW .input-group-text {
    height: 46px;
}

.GnrtPinDgtValOTP .modal-content {
    max-width: 274px !important;
}